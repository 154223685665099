import { TJPMReportTypes } from '../vendors/jpm/JPMorganTypes.js';

export enum TIntegrationExecutionFrequency {
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
  DAILY = 'DAILY',
  HOURLY = 'HOURLY',
}

export const TIntegrationExecutionFrequencyToMomentUnit: {
  [key in TIntegrationExecutionFrequency]: moment.DurationInputArg2;
} = {
  [TIntegrationExecutionFrequency.MONTHLY]: 'month',
  [TIntegrationExecutionFrequency.WEEKLY]: 'week',
  [TIntegrationExecutionFrequency.DAILY]: 'day',
  [TIntegrationExecutionFrequency.HOURLY]: 'hour',
};

export enum TIntegrationCsvType {
  APPLE_FINANCIAL_DETAIL = 'APPLE_FINANCIAL_DETAIL',
  APPLE_TAXES_ADJUSTMENTS = 'APPLE_TAXES_ADJUSTMENTS',
  NETSUITE = 'NETSUITE',
  ALLTRAILS_SALES = 'ALLTRAILS_SALES',
  SCRIBD_SVB_BANK_STATEMENT = 'SCRIBD_SVB_BANK_STATEMENT',
  ALLTRAILS_EARNINGS = 'ALLTRAILS_EARNINGS',
  ELYSIUM_GENERATE_GL_MAPPING = 'ELYSIUM_GENERATE_GL_MAPPING',
  GABB_AMAZON_BWP_FEES = 'GABB_AMAZON_BWP_FEES',
  GABB_AMAZON_SELLER_CENTRAL_FEES = 'GABB_AMAZON_SELLER_CENTRAL_FEES',
  GABB_AR_REPORT = 'GABB_AR_REPORT',
  GABB_BILLED_PLAN_SUBSCRIPTIONS = 'GABB_BILLED_PLAN_SUBSCRIPTIONS',
  GABB_CUSTOMERS = 'GABB_CUSTOMERS',
  GABB_ECOMM_ORDERS = 'GABB_ECOMM_ORDERS',
  GABB_ECOMM_ORDERS_PACKAGES = 'GABB_ECOMM_ORDERS_PACKAGES',
  GABB_ECOMM_ORDERS_PRODUCTS = 'GABB_ECOMM_ORDERS_PRODUCTS',
  GABB_ECOMM_ORDERS_PROMOTIONS = 'GABB_ECOMM_ORDERS_PROMOTIONS',
  GABB_ECOMM_PRODUCTS = 'GABB_ECOMM_PRODUCTS',
  GABB_ECOMM_PROMOTIONS = 'GABB_ECOMM_PROMOTIONS',
  GABB_INVOICE_HEADER_REPORT = 'GABB_INVOICE_HEADER_REPORT',
  GABB_INVOICE_HEADER_DETAIL = 'GABB_INVOICE_HEADER_DETAIL',
  GABB_LINES = 'GABB_LINES',
  GABB_GL_MAPPING = 'GABB_GL_MAPPING',
  GABB_PACKAGE_SUBSCRIPTIONS = 'GABB_PACKAGE_SUBSCRIPTIONS',
  GABB_PACKAGES = 'GABB_PACKAGES',
  GABB_PAYMENTECH_UNIFIED_DISPUTE_DETAIL = 'GABB_PAYMENTECH_UNIFIED_DISPUTE_DETAIL',
  GABB_PAYMENTECH_UNIFIED_FEE_DETAIL = 'GABB_PAYMENTECH_UNIFIED_FEE_DETAIL',
  GABB_PAYMENTECH_UNIFIED_SETTLEMENT_DETAIL = 'GABB_PAYMENTECH_UNIFIED_SETTLEMENT_DETAIL',
  GABB_PAYMENTECH_UNIFIED_SETTLEMENT_SUMMARY = 'GABB_PAYMENTECH_UNIFIED_SETTLEMENT_SUMMARY',
  GABB_PLAN_SUBSCRIPTIONS = 'GABB_PLAN_SUBSCRIPTIONS',
  GABB_PLANS = 'GABB_PLANS',
  GABB_TAX_DETAIL = 'GABB_TAX_DETAIL',
  GABB_TRANSACTION_REPORT = 'GABB_TRANSACTION_REPORT',
  GOOGLE_EARNINGS_REPORT = 'GOOGLE_EARNINGS_REPORT',
  HIPCAMP_BOOKING = 'HIPCAMP_BOOKING',
  HIPCAMP_CANCELLED_REVENUE = 'HIPCAMP_CANCELLED_REVENUE',
  HIPCAMP_MODIFICATION = 'HIPCAMP_MODIFICATION',
  HIPCAMP_PAYOUT = 'HIPCAMP_PAYOUT',
  HIPCAMP_GIFTCARD = 'HIPCAMP_GIFTCARD',
  HIPCAMP_REFUND = 'HIPCAMP_REFUND',
  HIPCAMP_STRIPE_FEE = 'HIPCAMP_STRIPE_FEE',
  HIPCAMP_STITCHED = 'HIPCAMP_STITCHED', // for main bookings
  HIPCAMP_STITCHED_GIFTCARD = 'HIPCAMP_STITCHED_GIFTCARD', // for giftcard purchases
  PAYZEN_SFTP_TRANSACTIONS_REPORT = 'PAYZEN_SFTP_TRANSACTIONS_REPORT',
  PAYZEN_SFTP_RECEIVABLES_REPORT = 'PAYZEN_SFTP_RECEIVABLES_REPORT',
  PAYZEN_NMI_SFTP_REPORT = 'PAYZEN_NMI_SFTP_REPORT',
  PAYZEN_HOMEGROWN_SFTP_FLYWIRE_REPORT = 'PAYZEN_HOMEGROWN_SFTP_FLYWIRE_REPORT',
  SCRIBD_PAYMENT_ORDERS = 'SCRIBD_PAYMENT_ORDERS',
  SCRIBD_ADYEN_PAYOUT = 'SCRIBD_ADYEN_PAYOUT',
  SCRIBD_PAYMENT_ORDER_ITEMS = 'SCRIBD_PAYMENT_ORDER_ITEMS',
  SCRIBD_PAYMENT_TRANSACTIONS = 'SCRIBD_PAYMENT_TRANSACTIONS',
  SCRIBD_PAYMENT_TRANSACTION_ATTRIBUTES = 'SCRIBD_PAYMENT_TRANSACTION_ATTRIBUTES',
  SCRIBD_GOOGLE_SALES = 'SCRIBD_GOOGLE_SALES',
  SCRIBD_APPLE_SALES = 'SCRIBD_APPLE_SALES',
  SCRIBD_ADYEN_SETTLEMENT_DETAIL = 'SCRIBD_ADYEN_SETTLEMENT_DETAIL',
  SCRIBD_ADYEN_EXTERNAL_SETTLEMENT_DETAIL = 'SCRIBD_ADYEN_EXTERNAL_SETTLEMENT_DETAIL',
  SCRIBD_PAYPAL_SETTLEMENT_DETAIL = 'SCRIBD_PAYPAL_SETTLEMENT_DETAIL',
  SCRIBD_EBANX_SETTLEMENT_DETAIL = 'SCRIBD_EBANX_SETTLEMENT_DETAIL',
  SCRIBD_BRAINTREE_SETTLEMENT_DETAIL = 'SCRIBD_BRAINTREE_SETTLEMENT_DETAIL',
  SCRIBD_BRAINTREE_DISPUTE_DETAIL = 'SCRIBD_BRAINTREE_DISPUTE_DETAIL',
  SCRIBD_FORTUMO_REVENUE_REPORT = 'SCRIBD_FORTUMO_REVENUE_REPORT',
  SCRIBD_AVALARA_TAX_RECORDS = 'SCRIBD_AVALARA_TAX_RECORDS',
}

export enum TIntegrationJsonType {
  JSONL_SHOPIFY_BULK_IMPORT = 'JSONL_SHOPIFY_BULK_IMPORT',
}

export const HipcampInputCsvTypesWithStitching = [
  TIntegrationCsvType.HIPCAMP_BOOKING,
  TIntegrationCsvType.HIPCAMP_CANCELLED_REVENUE,
  TIntegrationCsvType.HIPCAMP_MODIFICATION,
  TIntegrationCsvType.HIPCAMP_REFUND,
  TIntegrationCsvType.HIPCAMP_STRIPE_FEE,
];

export const HipcampInputCsvTypesWithoutStitching = [
  TIntegrationCsvType.HIPCAMP_PAYOUT,
  TIntegrationCsvType.HIPCAMP_GIFTCARD,
];

export type TShopifyIntegrationMetadata = {
  shops: {
    [name: string]: {
      prefix: string;
      mostRecentlyRetrievedDateStr?: string;
    };
  };
  subIntegrationIds?: {
    // Using TIntegrationTypes.PAYPAL from BaseTypes.js as key breaks the build
    PAYPAL: string;
  };
};

export type TPaymentechIntegrationReportMetadata = {
  [reportType in TJPMReportTypes]?: {
    mostRecentlyRetrievedDateStr?: string;
  };
};

export type TPaymentechIntegrationMetadata = {
  reports: TPaymentechIntegrationReportMetadata;
  s3Bucket: string;
  s3FileTypes: {
    reportType: TJPMReportTypes;
    csvType: TIntegrationCsvType;
    retrievedFromUri?: string;
    lastRetrievedAtMs?: number;
  }[];
};

export type TIntegrationModelAdditionalDataHealthie = {
  lastProcessedPayoutCreatedAt: number;
  lastProcessedPayoutId: string;
};

export type TIntegrationModelAdditionalData = {
  healthie?: TIntegrationModelAdditionalDataHealthie;
};
