/*
  Flags specifically checked by the client
*/

// TODO: this should be passed in via SiteConstants->DOM instead
export const isOnProduction = document.location.hostname === 'app.gonumeral.com';

export const publicRoutes = [
  '/maintenance',
  '/login-otp',
  '/forgot-password',
  '/email-sent',
  '/reset-password',
  '/reset-success',
  '/join',
  '/',
  '/login',
];
