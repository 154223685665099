import { TNavItem } from '../../../src/types/BaseTypes.ts';

export const TOGGLE_WIDTH_PX = 22;

export const ALWAYS_HIDDEN = [
  TNavItem.COMPANY_SETTINGS,
  TNavItem.MONITORING,
  TNavItem.AUDIT_TRAIL,
  TNavItem.EXCEPTIONS,
  TNavItem.RULES,
  TNavItem.TRANSACTION,
  TNavItem.PRICE_LIST,
];

export const HIDDEN_FOR_NON_BANK_RECON_COMPANIES = [TNavItem.DEPOSITS, TNavItem.RECONCILIATION];

export const SHOW_TRANSACTION = [TNavItem.TRANSACTION];

export const COMPANY_WITH_TRANSACTION_SUPPORT = ['healthie'];
