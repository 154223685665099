export enum TLedgerAccountCategoryTypes {
  CASH_ACCOUNTS = 'CASH_ACCOUNTS',
}

export enum TLedgerAccountTypes {
  /**
   * Recognized Values.
   * These are values that from an accounting side have been recognized in a given time period.
   */
  DEFERRED_REVENUE_RECOGNIZED = 'DEFERRED_REVENUE_RECOGNIZED',
  NON_DEFERRED_REVENUE_RECOGNIZED = 'NON_DEFERRED_REVENUE_RECOGNIZED',

  DISCOUNT = 'DISCOUNT', // this can also be interpreted as non deferred discount recognized
  DEFERRED_DISCOUNT_RECOGNIZED = 'DEFERRED_DISCOUNT_RECOGNIZED',
  DEFERRED_DISCOUNT_WRITEOFF = 'DEFERRED_DISCOUNT_WRITEOFF',

  // revenue refund bucket; if a deferred balance is refunded, the amount recognized does not leave that bucket
  // but goes to this one
  DEFERRED_REVENUE_REFUNDED = 'DEFERRED_REVENUE_REFUNDED',
  DEFERRED_DISCOUNT_REFUNDED = 'DEFERRED_DISCOUNT_REFUNDED',

  // Contra Revenue is used to place refunds that have been recognized into a new account
  CONTRA_REVENUE = 'CONTRA_REVENUE',
  // Contra Discount is the same as above
  CONTRA_DISCOUNT = 'CONTRA_DISCOUNT',

  // Cash rec that we are treating like a revenue rec for AllTrails
  TRANSACTION_FEE = 'TRANSACTION_FEE',

  /**
   * Balance Values
   * These are values that are received at the time of purchase/action.
   * Over time we should see these values get recognized and by the end be zeroed out
   * (all positive entries equal negative entries)
   */
  DEFERRED_REVENUE_BALANCE = 'DEFERRED_REVENUE_BALANCE',
  DEFERRED_DISCOUNT_BALANCE = 'DEFERRED_DISCOUNT_BALANCE',
  // AllTrails specific (could be others in future)
  DEFERRED_TRANSACTION_FEE = 'DEFERRED_TRANSACTION_FEE',
  DEFERRED_TRANSACTION_FEE_BALANCE = 'DEFERRED_TRANSACTION_FEE_BALANCE',

  /**
   * Cash Reconciliation Values
   * As money moves these are the factors for how that money moves and any expenses/differences.
   */
  CASH = 'CASH',
  DEPOSIT_IN_TRANSIT = 'DEPOSIT_IN_TRANSIT',
  TAX_PAYABLE = 'TAX_PAYABLE',
  FX_CONVERSION_COST = 'FX_CONVERSION_COST',
  ACCOUNTS_RECEIVABLE = 'ACCOUNTS_RECEIVABLE',
  PAYOUT = 'PAYOUT',
  RECON_DIFERRENCES = 'RECON_DIFERRENCES',
  // currently, for stripe connected account transfers
  TRANSFER = 'TRANSFER',
  DEPOSIT_PENDING = 'DEPOSIT_PENDING',
  RECONCILED_PAYOUT = 'RECONCILED_PAYOUT',
  NON_RECONCILED_PAYOUT = 'NON_RECONCILED_PAYOUT',

  /**
   * Other values
   */
  // Hipcamp specific
  GIFT_CARD_LIABILITY = 'GIFT_CARD_LIABILITY',
  HOST_PAYABLE = 'HOST_PAYABLE',
  EMPLOYEE_BENEFITS = 'EMPLOYEE_BENEFITS',

  // recorded from transaction-level data before any accounting logic is applied
  PAYMENT = 'PAYMENT',
  DISPUTE = 'DISPUTE',
  REFUND = 'REFUND',
  DEFERRED_REVENUE_BALANCE_WRITEOFF = 'DEFERRED_REVENUE_BALANCE_WRITEOFF',
  ACCOUNTS_RECEIVABLE_WRITEOFF = 'ACCOUNTS_RECEIVABLE_WRITEOFF',

  // A Temporary value used for AlLTrails to determine that a giftCard was used.  Does not create a LAE
  // Can be used by others
  GIFT_CARD = 'GIFT_CARD',

  // PayZen specific
  PAYZEN_TRANSACTION_VOLUME_MARGIN = 'PAYZEN_TRANSACTION_VOLUME_MARGIN',
  PAYZEN_TRANSACTION_VOLUME = 'PAYZEN_TRANSACTION_VOLUME',
  PAYZEN_PAYMENTS_TO_PROVIDER = 'PAYZEN_PAYMENTS_TO_PROVIDER',

  // For debugging, create journal entries for invoice, credit, refunds without any deferral schedule.
  INVOICE = 'INVOICE',
  INVOICE_ITEM = 'INVOICE_ITEM',
  UNRECONCILED_INVOICE = 'UNRECONCILED_INVOICE',
  CREDIT_ITEM = 'CREDIT_ITEM',
  REFUND_ITEM = 'REFUND_ITEM',
  NON_DEFERRED_REFUND = 'NON_DEFERRED_REFUND',
  DEFERRED_REFUND_BALANCE = 'DEFERRED_REFUND_BALANCE',
  TRANSACTION_FEE_REFUND = 'TRANSACTION_FEE_REFUND',
  DEFERRED_TRANSACTION_FEE_REFUND = 'DEFERRED_TRANSACTION_FEE_REFUND',
  DEFERRED_TRANSACTION_FEE_REFUND_BALANCE = 'DEFERRED_TRANSACTION_FEE_REFUND_BALANCE',
  TAX_REFUND = 'TAX_REFUND',
}

export const LedgerRevenueGenertingAccounts: TLedgerAccountTypes[] = [
  TLedgerAccountTypes.NON_DEFERRED_REVENUE_RECOGNIZED,
  TLedgerAccountTypes.DEFERRED_REVENUE_RECOGNIZED,
];

export const LedgerAccountLabels: { [key in TLedgerAccountTypes]: string } = {
  [TLedgerAccountTypes.DEFERRED_REVENUE_BALANCE]: 'Deferred Revenue Rollforward',
  [TLedgerAccountTypes.DEFERRED_REVENUE_RECOGNIZED]: 'Recognized from Deferred Revenue',
  [TLedgerAccountTypes.NON_DEFERRED_REVENUE_RECOGNIZED]: 'Recognized Immediately',
  [TLedgerAccountTypes.ACCOUNTS_RECEIVABLE]: 'Accounts Receivable Rollforward',
  [TLedgerAccountTypes.CASH]: 'Payouts',
  [TLedgerAccountTypes.DEPOSIT_IN_TRANSIT]: 'Deposits in Transit to PSP',
  [TLedgerAccountTypes.TRANSACTION_FEE]: 'Transaction Fees',
  [TLedgerAccountTypes.TAX_PAYABLE]: 'Tax Collected',
  [TLedgerAccountTypes.FX_CONVERSION_COST]: 'Foreign Exchange Conversion Cost in Period',
  [TLedgerAccountTypes.RECON_DIFERRENCES]: 'Reconciliation Diferences',
  [TLedgerAccountTypes.DEPOSIT_PENDING]: 'Pending Deposits for Booked Cash',
  [TLedgerAccountTypes.RECONCILED_PAYOUT]: 'Reconciled Payouts',
  [TLedgerAccountTypes.NON_RECONCILED_PAYOUT]: 'Non Reconciled Payouts',

  [TLedgerAccountTypes.DISCOUNT]: 'Discount',
  [TLedgerAccountTypes.GIFT_CARD_LIABILITY]: 'Gift Card Liability',

  [TLedgerAccountTypes.HOST_PAYABLE]: 'Host Payable',
  [TLedgerAccountTypes.EMPLOYEE_BENEFITS]: 'Employee Benefits',
  [TLedgerAccountTypes.DEFERRED_DISCOUNT_BALANCE]: 'Deferred Discount',

  [TLedgerAccountTypes.PAYMENT]: 'Total Payment',
  [TLedgerAccountTypes.DISPUTE]: 'Total Dispute',
  [TLedgerAccountTypes.REFUND]: 'Total Refund',
  [TLedgerAccountTypes.PAYOUT]: 'Reconciled Payouts in Bank Account',
  [TLedgerAccountTypes.DEFERRED_REVENUE_BALANCE_WRITEOFF]: 'Deferred Revenue Written Off',
  [TLedgerAccountTypes.ACCOUNTS_RECEIVABLE_WRITEOFF]: 'Accounts Receivable Written Off',

  [TLedgerAccountTypes.DEFERRED_TRANSACTION_FEE]: 'Deferred Transaction Fee',
  [TLedgerAccountTypes.DEFERRED_TRANSACTION_FEE_BALANCE]: 'Deferred Transaction Fee Rollforward',

  // TODO: fix this to be more generic. Currently only used for Stripe connected accounts
  [TLedgerAccountTypes.TRANSFER]: 'Connected Account Transfers',
  [TLedgerAccountTypes.GIFT_CARD]: 'Gift Card',
  [TLedgerAccountTypes.DEFERRED_DISCOUNT_RECOGNIZED]: 'Deferred Discount',

  [TLedgerAccountTypes.DEFERRED_REVENUE_REFUNDED]: 'Deferred Revenue Refunded',
  [TLedgerAccountTypes.DEFERRED_DISCOUNT_REFUNDED]: 'Deferred Revenue Refunded',
  [TLedgerAccountTypes.DEFERRED_DISCOUNT_WRITEOFF]: 'Deferred Discount Written Off',

  [TLedgerAccountTypes.PAYZEN_TRANSACTION_VOLUME_MARGIN]: 'Transaction Volume Margin',
  [TLedgerAccountTypes.PAYZEN_TRANSACTION_VOLUME]: 'Transaction Volume',
  [TLedgerAccountTypes.PAYZEN_PAYMENTS_TO_PROVIDER]: 'Payments to Provider',

  [TLedgerAccountTypes.CONTRA_REVENUE]: 'Contra Revenue',
  [TLedgerAccountTypes.UNRECONCILED_INVOICE]: 'Unreconciled Invoice',
  [TLedgerAccountTypes.INVOICE_ITEM]: 'Invoice Item',
  [TLedgerAccountTypes.INVOICE]: 'Invoice',
  [TLedgerAccountTypes.CREDIT_ITEM]: 'Credit',
  [TLedgerAccountTypes.REFUND_ITEM]: 'Refund',
  [TLedgerAccountTypes.NON_DEFERRED_REFUND]: 'Non Deferred Refund',
  [TLedgerAccountTypes.DEFERRED_REFUND_BALANCE]: 'Deferred Refund Balance',
  [TLedgerAccountTypes.CONTRA_DISCOUNT]: '',
  [TLedgerAccountTypes.TRANSACTION_FEE_REFUND]: 'Transaction Fee Refund',
  [TLedgerAccountTypes.TAX_REFUND]: 'Tax Refund',
  [TLedgerAccountTypes.DEFERRED_TRANSACTION_FEE_REFUND]: 'Deferred Transaction Fee Refund',
  [TLedgerAccountTypes.DEFERRED_TRANSACTION_FEE_REFUND_BALANCE]: 'Deferred Transaction Fee Refund Rollforward',
};

export enum TNumeralDimensionSource {
  INTEREST = 'interest',
  PRINCIPAL = 'principal',
  PLATFORM_FEE = 'platform_fee',
}

// these are numeral imposed dimensions.
// try to keep the value short, use abbreviations
export enum TNumeralDefaultDimensions {
  BALANCE_ACCRUED_MONTH = 'balance_accrued_month', // only applicable to DEFERRED_REVENUE_RECOGNIZED
  IS_CANCELLATION = 'is_cancellation', // only applicable to DEFERRED_REVENUE_BALANCE
  IS_DISCOUNT = 'is_discount',
  IS_DISPUTE = 'is_dispute',
  IS_DISPUTE_REVERSAL = 'is_dispute_reversal',
  IS_GIFT_CARD = 'is_gift_card', // only applicable to DEFERRED_DISCOUNT_BALANCE.
  IS_PREPAYMENT = 'is_prepayment',
  IS_USE_PREPAYMENT = 'is_use_prepayment',
  IS_NEGATIVE = 'is_negative', // only applicable to DEFERRED_REVENUE_BALANCE and ACCOUNTS_RECEIVABLE
  IS_REFUND = 'is_refund', // only applicable to DEFERRED_REVENUE_BALANCE for cancelling out remaining amounts
  IS_USED_GIFT_CARD = 'used_gift_card',
  IS_TAX_INVOICED = 'is_tax_invoiced',
  CANCELLATION_TYPE = 'cancellation_type',
  PAYMENT_VENDOR = 'payment_vendor',
  PRICE_LIST_ITEM_ID = 'price_list_item_id',
  SOURCE = 'source',
  VENDOR = 'vendor',
  ENTRY = 'entry', // debit or credit
  BUSINESS_ENTITY = 'business_entity',
  RECOGNIZED_CATEGORY = 'revrec_category',
  SKU = 'sku',
  STATE_PROVINCE = 'state', // state/province location
  COUNTRY = 'country',
  COUNTRY_NOT = 'country_not', // hack to group all dimensions that aren't a specific country
  REFUND_TYPE = 'refund_type', // refund type (refund, dispute, dispute_reversal, etc)
  REFUND_DAYS_ELAPSED = 'refundDaysElapsed',
  DURATION = 'duration',
  PLAN_TYPE = 'plan_type',
  ACCOUNTS_RECEIVABLE_ENTRY_TYPE = 'accounts_receivable_entry_type',
}

export enum TNumeralDefaultCancellationTypes {
  CANCELLED = 'cancelled',
  WRITE_OFF = 'write_off',
}

export enum TNumeralDefaultAccountsReceivableEntryTypes {
  ADJUSTMENTS = 'adjustments',
  ADDITION = 'addition',
  COLLECTION = 'collection',
}

export enum TNumeralDefaultValues {
  CREDIT = 'cr', // journal entry credit or debit
  DEBIT = 'dr',
  RECOGNIZED_SAME_BILLING_MONTH = 'rec_same_month', // deferred revenue recognized in the same month the invoice start date
  RECOGNIZED_FROM_DEFERRED = 'rec_deferred', // recognized from deferred revenue
}

export const DIMENSION_SEPARATOR = ',';

export const TRevenueReportRowLabels = {
  ...LedgerAccountLabels,
  CASH_DEPOSITED: 'Cash Deposited',
};

export enum TRevenueReportSection {
  PROFIT_AND_LOSS = 'P&L',
  BALANCE_SHEET = 'Balance Sheet',
  LIABILITIES = 'Liabilities',
  EXPENSES = 'Expenses',
  CASH = 'Cash',

  // Alternate options for specific cases
  ALTERNATE_SECTION_CASH_DEPOSITED = 'Cash Deposited',
  ALTERNATE_SECTION_CASH_PAYMENTS = 'Cash Payments',
  ALTERNATE_SECTION_REVENUE = 'Revenue',

  // Company-specific options
  PAYZEN_ORIGINATION_SUMMARY = 'Origination',
  GABB_REVENUE_SUMMARY = 'Total Revenue Recognized',
  HEALTHIE_REVENUE_SUMMARY = '',
  SCRIBD_REVENUE_SUMMARY = '',

  TRANSACTION_DATA = 'Transaction Data',
}

export enum TRevenueReportSummaryRows {
  TOTAL_PAYMENTS_COLLECTED = 'Total Payments Collected',
  TOTAL_REVENUE_RECOGNIZED = 'Total Revenue Recognized',
  TOTAL_ASSETS = 'Total Assets',
  GROSS_MARGIN = 'Gross Margin',
  TOTAL_LIABILITIES = 'Total Liabilities',
  DEPOSITED = 'Deposited',
  DEPOSITS_IN_TRANSIT = 'Undeposited Funds / DIT',

  // Alternative labels
  ALTERNATE_DEPOSITED_TOTAL_SETTLED = 'Total Settled',
}

// not used for Company#revRecV2 companies
export const getRevenueReportSectionForAccountType = (accountType: TLedgerAccountTypes) => {
  const ledgerAccountsToReportSections: { [key in TLedgerAccountTypes]: TRevenueReportSection } = {
    [TLedgerAccountTypes.DEFERRED_REVENUE_BALANCE]: TRevenueReportSection.LIABILITIES,
    [TLedgerAccountTypes.DEFERRED_REVENUE_BALANCE_WRITEOFF]: TRevenueReportSection.PROFIT_AND_LOSS,
    [TLedgerAccountTypes.DEFERRED_REVENUE_RECOGNIZED]: TRevenueReportSection.PROFIT_AND_LOSS,
    [TLedgerAccountTypes.NON_DEFERRED_REVENUE_RECOGNIZED]: TRevenueReportSection.PROFIT_AND_LOSS,
    [TLedgerAccountTypes.DISCOUNT]: TRevenueReportSection.PROFIT_AND_LOSS,
    [TLedgerAccountTypes.DEFERRED_DISCOUNT_RECOGNIZED]: TRevenueReportSection.PROFIT_AND_LOSS,

    [TLedgerAccountTypes.GIFT_CARD_LIABILITY]: TRevenueReportSection.LIABILITIES,
    [TLedgerAccountTypes.TAX_PAYABLE]: TRevenueReportSection.LIABILITIES,

    // Hipcamp specific
    [TLedgerAccountTypes.HOST_PAYABLE]: TRevenueReportSection.LIABILITIES,
    [TLedgerAccountTypes.EMPLOYEE_BENEFITS]: TRevenueReportSection.EXPENSES,
    [TLedgerAccountTypes.DEFERRED_DISCOUNT_BALANCE]: TRevenueReportSection.LIABILITIES,

    // recorded from transaction-level data before any accounting logic is applied
    [TLedgerAccountTypes.PAYMENT]: TRevenueReportSection.PROFIT_AND_LOSS,
    [TLedgerAccountTypes.DISPUTE]: TRevenueReportSection.PROFIT_AND_LOSS,
    [TLedgerAccountTypes.REFUND]: TRevenueReportSection.PROFIT_AND_LOSS,
    [TLedgerAccountTypes.PAYOUT]: TRevenueReportSection.BALANCE_SHEET,

    [TLedgerAccountTypes.TRANSACTION_FEE]: TRevenueReportSection.EXPENSES,

    [TLedgerAccountTypes.ACCOUNTS_RECEIVABLE]: TRevenueReportSection.BALANCE_SHEET,
    [TLedgerAccountTypes.ACCOUNTS_RECEIVABLE_WRITEOFF]: TRevenueReportSection.BALANCE_SHEET,
    [TLedgerAccountTypes.CASH]: TRevenueReportSection.BALANCE_SHEET,
    [TLedgerAccountTypes.DEPOSIT_IN_TRANSIT]: TRevenueReportSection.BALANCE_SHEET,
    [TLedgerAccountTypes.FX_CONVERSION_COST]: TRevenueReportSection.BALANCE_SHEET,
    [TLedgerAccountTypes.DEFERRED_TRANSACTION_FEE]: TRevenueReportSection.BALANCE_SHEET,
    [TLedgerAccountTypes.DEFERRED_TRANSACTION_FEE_BALANCE]: TRevenueReportSection.LIABILITIES,
    [TLedgerAccountTypes.DEPOSIT_PENDING]: TRevenueReportSection.BALANCE_SHEET,
    [TLedgerAccountTypes.NON_RECONCILED_PAYOUT]: TRevenueReportSection.BALANCE_SHEET,
    [TLedgerAccountTypes.RECONCILED_PAYOUT]: TRevenueReportSection.BALANCE_SHEET,

    [TLedgerAccountTypes.TRANSFER]: TRevenueReportSection.BALANCE_SHEET,
    [TLedgerAccountTypes.RECON_DIFERRENCES]: TRevenueReportSection.BALANCE_SHEET,
    [TLedgerAccountTypes.GIFT_CARD]: TRevenueReportSection.PROFIT_AND_LOSS,

    [TLedgerAccountTypes.DEFERRED_REVENUE_REFUNDED]: TRevenueReportSection.PROFIT_AND_LOSS,
    [TLedgerAccountTypes.DEFERRED_DISCOUNT_REFUNDED]: TRevenueReportSection.PROFIT_AND_LOSS,
    [TLedgerAccountTypes.DEFERRED_DISCOUNT_WRITEOFF]: TRevenueReportSection.PROFIT_AND_LOSS,

    [TLedgerAccountTypes.PAYZEN_TRANSACTION_VOLUME_MARGIN]: TRevenueReportSection.PAYZEN_ORIGINATION_SUMMARY,
    [TLedgerAccountTypes.PAYZEN_PAYMENTS_TO_PROVIDER]: TRevenueReportSection.PAYZEN_ORIGINATION_SUMMARY,
    [TLedgerAccountTypes.PAYZEN_TRANSACTION_VOLUME]: TRevenueReportSection.PAYZEN_ORIGINATION_SUMMARY,

    [TLedgerAccountTypes.CONTRA_REVENUE]: TRevenueReportSection.PROFIT_AND_LOSS,
    [TLedgerAccountTypes.INVOICE]: TRevenueReportSection.BALANCE_SHEET,
    [TLedgerAccountTypes.INVOICE_ITEM]: TRevenueReportSection.BALANCE_SHEET,
    [TLedgerAccountTypes.REFUND_ITEM]: TRevenueReportSection.BALANCE_SHEET,
    [TLedgerAccountTypes.NON_DEFERRED_REFUND]: TRevenueReportSection.PROFIT_AND_LOSS,
    [TLedgerAccountTypes.DEFERRED_REFUND_BALANCE]: TRevenueReportSection.BALANCE_SHEET,
    [TLedgerAccountTypes.CREDIT_ITEM]: TRevenueReportSection.BALANCE_SHEET,
    [TLedgerAccountTypes.CONTRA_DISCOUNT]: TRevenueReportSection.PROFIT_AND_LOSS,

    [TLedgerAccountTypes.UNRECONCILED_INVOICE]: TRevenueReportSection.BALANCE_SHEET,
    [TLedgerAccountTypes.TRANSACTION_FEE_REFUND]: TRevenueReportSection.BALANCE_SHEET,
    [TLedgerAccountTypes.DEFERRED_TRANSACTION_FEE_REFUND]: TRevenueReportSection.BALANCE_SHEET,
    [TLedgerAccountTypes.DEFERRED_TRANSACTION_FEE_REFUND_BALANCE]: TRevenueReportSection.BALANCE_SHEET,
    [TLedgerAccountTypes.TAX_REFUND]: TRevenueReportSection.BALANCE_SHEET,
  };
  return ledgerAccountsToReportSections[accountType];
};

export const getRevenueReportSectionForSummaryRowLabel = (label: TRevenueReportSummaryRows) => {
  const sections: { [key in TRevenueReportSummaryRows]?: TRevenueReportSection } = {
    [TRevenueReportSummaryRows.GROSS_MARGIN]: TRevenueReportSection.PROFIT_AND_LOSS,
    [TRevenueReportSummaryRows.TOTAL_ASSETS]: TRevenueReportSection.BALANCE_SHEET,
    [TRevenueReportSummaryRows.TOTAL_LIABILITIES]: TRevenueReportSection.BALANCE_SHEET,
    [TRevenueReportSummaryRows.TOTAL_PAYMENTS_COLLECTED]: TRevenueReportSection.BALANCE_SHEET,
    [TRevenueReportSummaryRows.TOTAL_REVENUE_RECOGNIZED]: TRevenueReportSection.PROFIT_AND_LOSS,
  };
  return sections[label];
};

export const journalEntryAccountsOrdered = [
  TLedgerAccountTypes.ACCOUNTS_RECEIVABLE,
  TLedgerAccountTypes.DEPOSIT_IN_TRANSIT,
  TLedgerAccountTypes.TRANSACTION_FEE,
  TLedgerAccountTypes.DEFERRED_TRANSACTION_FEE,
  TLedgerAccountTypes.DEFERRED_TRANSACTION_FEE_BALANCE,
  TLedgerAccountTypes.TAX_PAYABLE,
  TLedgerAccountTypes.DISCOUNT,
  TLedgerAccountTypes.DEFERRED_DISCOUNT_BALANCE,
  TLedgerAccountTypes.DEFERRED_DISCOUNT_RECOGNIZED,
  TLedgerAccountTypes.FX_CONVERSION_COST,
  TLedgerAccountTypes.CASH,
  TLedgerAccountTypes.NON_DEFERRED_REVENUE_RECOGNIZED,
  TLedgerAccountTypes.DEFERRED_REVENUE_RECOGNIZED,
  TLedgerAccountTypes.DEFERRED_REVENUE_BALANCE,
  TLedgerAccountTypes.ACCOUNTS_RECEIVABLE_WRITEOFF,
  TLedgerAccountTypes.DEFERRED_REVENUE_BALANCE_WRITEOFF,
  TLedgerAccountTypes.DEFERRED_DISCOUNT_WRITEOFF,
  TLedgerAccountTypes.PAYZEN_TRANSACTION_VOLUME,
  TLedgerAccountTypes.PAYZEN_PAYMENTS_TO_PROVIDER,
  TLedgerAccountTypes.PAYZEN_TRANSACTION_VOLUME_MARGIN,
];

export enum TLedgerDirection {
  CREDIT = 'credit',
  DEBIT = 'debit',
}

export enum TLedgerDirectionAbbreviated {
  CREDIT = 'CR',
  DEBIT = 'DR',
}

export type TGenerateLedgerAccountEntriesHandlerResults = {
  totalScanned?: number;
  totalHandled?: number;
  hasMoreToProcess?: boolean;
  curId?: string;
  runnerChosen?: string;
};
